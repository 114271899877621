import { mxCell, mxGraph } from '@anekonnect/mxgraph';

import { ComponentData, Ref } from '../../Types';

import { BezierCores, PreviousGeo } from './Types';
import Constant from './Constant';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Possible = any;
type Dict<T> = Record<string, T>;

export const isMultiCableType = (bezierData: ComponentData, type: string) => {
  const bezierDataCores = bezierData['cores'] as BezierCores[];

  return bezierDataCores.filter((v) => v.type === type).length > 1 ? true : false;
};

/**
 * Return center position from cell
 * @param  {number} parentOffset offset x or y from parent
 * @param  {number} parentGeo width or height from parent
 * @param  {number} childGeo offset x or y from child
 */
export const getCenter = (parentOffset: number, parentGeo: number, childGeo: number) => {
  return parentOffset + (parentGeo - childGeo) / 2;
};

/**
 * Replace field {cable_core_arrangement} to standard value
 * @param  {ComponentData} bezierData
 */
export const replCableCoreArrangement = (str: string) => {
  const regex = /^[^-]*[^ -$]/g;
  const result = str.replace(regex, '');

  return `#${result}`;
};

/**
 * Get gauge and units from cores
 * @param  {ComponentData} data
 * @param  {number} index
 */
export const getLabelPin = (data: ComponentData, key: string, index: number) => {
  const gauge = data[key][index]?.gauge;
  const units = data[key][index]?.units;

  return {
    gauge,
    units,
  };
};

/**
 * Return last value from range and len
 * @param  {Dict<Possible>[]} items
 * @param  {string} keyName
 */
export const filterCable = (items: Dict<Possible>[], keyName: string) => {
  type Field = {
    idx: number[];
    type: string;
    coreStructure: string;
  };

  const idxs: Field = {
    idx: [],
    type: '',
    coreStructure: '',
  };
  const nums: number[] = [];
  const result: Field[] = [];

  const { count: len } = items.reduce((acc, cv) => {
    return {
      ...acc,
      // Count all value of {keyname} field
      count: ((acc[keyName] as number) + cv[keyName]) as number,
    };
  });

  // Create range of array, start from index
  for (let i = 0; i < len; i++) {
    nums.push(i);
  }

  // Get value, until value of {keyname} field
  items.map((v, index) => {
    for (let i = 0; i < v.count; i++) {
      idxs.idx[index] = i;
    }

    return v;
  });

  // Splice the value, from range
  idxs.idx.map((v, index) =>
    result.push({
      idx: nums.splice(0, v + 1),
      type: items[index]['type'],
      coreStructure: items[index]['core_structure'],
    }),
  );

  return result;
};

/** Get total height of container
 * @param  {ComponentData} data
 */
export const getTotalHeightOfContainer = (data: ComponentData) => {
  let totalHeight = 0;
  const lengths: number[] = [];

  filterCable(data.cores, 'count').map(({ idx, type }) => {
    switch (type) {
      case 'Standard':
        lengths.push(Constant.cablePinHeight.standard * idx.length);
        break;
      case 'Drain':
        lengths.push(Constant.cablePinHeight.drain * idx.length);
        break;
      case 'TwistedPair':
        lengths.push(Constant.cablePinHeight.shieldedTP * idx.length);
        break;
      case 'ShieldedStandard':
        lengths.push(Constant.cablePinHeight.shieldedStandard * idx.length);
        break;
      case 'ShieldedTP':
        lengths.push(Constant.cablePinHeight.shieldedTP * idx.length);
        break;
      case 'Coax':
      case 'ShieldedCoax':
        lengths.push(Constant.cablePinHeight.shieldedCoax * idx.length);
        break;
      case 'FiberOptic':
        lengths.push(Constant.cablePinHeight.fiberOptic * idx.length);
        break;
      case 'ShieldedFiberOptic':
        lengths.push(Constant.cablePinHeight.shieldedFiberOptic * idx.length);
        break;
      case 'QuadTwisted':
      case 'QunitupletTwisted':
      case 'ShieldedQuadTwisted':
        lengths.push(Constant.cablePinHeight.shieldedQuadTwisted * (idx.length * 4));
        break;
      case 'TriTwisted':
        lengths.push(Constant.cablePinHeight.triTwisted * (idx.length * 3));
        break;
      case 'ShieldedTriTwisted': {
        lengths.push(Constant.cablePinHeight.shieldedTriTwisted * (idx.length * 3));
        break;
      }
      default:
        console.error('Please add more type/case in get total height');
        break;
    }

    return idx;
  });

  totalHeight = lengths.reduce((acc, cv) => acc + cv) || 0;

  if (data['is_shielded']) {
    totalHeight += 15;
  }

  return totalHeight;
};

/**
 * @param  {ComponentData} data
 * @return boolean
 */
export const isSolid = (data: ComponentData) => {
  let result = false;

  filterCable(data.cores, 'count').map(({ coreStructure }) => {
    const isSolidStructure = coreStructure === 'solid';

    if (isSolidStructure) {
      result = isSolidStructure;
    } else {
      result = false;
    }

    return coreStructure;
  });

  return result;
};

type CableHeight = {
  cableType: string;
  height: number;
};
export const currentComponentPinHeight = (data: ComponentData) => {
  let heights: CableHeight[] = [];

  heights = [];
  filterCable(data.cores, 'count').map(({ type }) => {
    switch (type) {
      case 'Standard':
      case 'Drain':
      case 'ShieldedStandard':
        heights.push({
          cableType: type,
          height: Constant.cablePinHeight.shieldedStandard,
        });
        break;
      case 'ShieldedTP':
      case 'TwistedPair':
        heights.push({
          cableType: type,
          height: Constant.cablePinHeight.shieldedTP,
        });
        break;
      case 'Coax':
      case 'ShieldedCoax':
        heights.push({
          cableType: type,
          height: Constant.cablePinHeight.shieldedCoax,
        });
        break;
      case 'FiberOptic':
        heights.push({
          cableType: type,
          height: Constant.cablePinHeight.fiberOptic,
        });
        break;
      case 'ShieldedFiberOptic':
        heights.push({
          cableType: type,
          height: Constant.cablePinHeight.shieldedFiberOptic,
        });
        break;
      case 'QuadTwisted':
      case 'QunitupletTwisted':
      case 'ShieldedQuadTwisted':
        heights.push({
          cableType: type,
          height: Constant.cablePinHeight.shieldedQuadTwisted,
        });
        break;
      case 'TriTwisted':
        heights.push({
          cableType: type,
          height: Constant.cablePinHeight.triTwisted,
        });
        break;
      case 'ShieldedTriTwisted':
        heights.push({
          cableType: type,
          height: Constant.cablePinHeight.shieldedTriTwisted,
        });
        break;
      default:
        // eslint-disable-next-line no-console
        console.error('Please add more type/case in current component height');
        break;
    }

    return type;
  });

  return heights;
};

export const getCurrentLastOffsetY = (
  geometryRef: Ref<PreviousGeo[]>,
  key: number,
  index: number,
  currentHeight: number,
  prevHeight: number,
) => {
  let currentlastOffsetY = 0;
  const lastOffsetRef = geometryRef.current[key - 1]?.lastOffsetY || 0;

  if (key === 0) {
    currentlastOffsetY = index * currentHeight;
  } else {
    currentlastOffsetY = lastOffsetRef + prevHeight;
  }

  return {
    currentlastOffsetY,
  };
};

export const getPrevHeight = (data: ComponentData, key: number) => {
  let result = 0;

  const isFirstOffset = !!data.cores[key - 1]?.type || false;

  if (isFirstOffset) {
    const prevType = data.cores[key - 1].type;
    result = currentComponentPinHeight(data).filter(({ cableType }) => cableType === prevType)[0]
      .height;
  }

  return result;
};

// Only call once
export const callFnOnce =
  (fn: (...fnArgs: Possible) => void, type: string, callFnRef: Ref<boolean>) =>
  (graph: mxGraph, data: ComponentData, parent: mxCell, geometryRef: Ref<PreviousGeo[]>) => {
    if (isMultiCableType(data, type)) {
      if (!callFnRef.current) {
        fn(graph, data, parent, geometryRef);
        callFnRef.current = true;
      }
    } else {
      fn(graph, data, parent, geometryRef);
    }
  };
