import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';

import PartsFilterAddModal from '~/pages/parts/components/PartsFilter/PartsFilterAddModal';
import { Part } from '~/api/API';
import { getPartFilters } from '~/api/AuthorizedGets';
import { AppState } from '~/store/reducers';
import { KeyValue, PartFilterFields } from '~/types';
import { getParts, selectPartByPartName } from '~/utils/parts';
import { isStateLoading } from '~/utils/state';
import { objectFromEntries } from '~/utils/helper';
import {
  convertStringKeyToObject,
  createDeepObject,
  extractNestedKey,
  isMultipleFilter,
} from '~/utils/filter';

type PropsFromState = {
  partFiltersFields: PartFilterFields[];
  part: Part | null;
  isLoading: boolean;
};

type WizardHeaderParametricSearchProps = {
  showModal: boolean;
  modalCloseHandler: () => void;
  filters: KeyValue;
  handleFiltering: (filter: KeyValue) => void;
  noDefaultFilterField?: boolean;
  selectedPartName?: string;
};

const WizardHeaderParametricSearch = ({
  showModal,
  modalCloseHandler,
  filters,
  handleFiltering,
  selectedPartName,
  partFiltersFields,
  part,
  isLoading,
}: WizardHeaderParametricSearchProps & PropsFromState) => {
  const [selectedFilters, setSelectedFilters] = useState<PartFilterFields[]>([]);
  const [selectedFiltersData, setSelectedFiltersData] = useState<KeyValue>({});
  const [partFiltersData, setPartFiltersData] = useState<KeyValue<KeyValue<number>>>({});

  const handleSelectedFilters = useCallback(() => {
    const filterKeys = Object.keys(filters);

    const filterPartFilters = (
      partFilters: PartFilterFields[],
      filterKeys: string[],
    ): PartFilterFields[] => {
      return partFilters.filter((partFilter) => {
        if (isMultipleFilter(partFilter)) {
          return filterKeys.some((key) => {
            if (key.includes('multiple')) {
              const nestedKey = extractNestedKey(key);
              return partFilter.key === nestedKey;
            }
            return false;
          });
        } else {
          return filterKeys.includes(partFilter.key);
        }
      });
    };

    const filtersSelected = filterPartFilters(partFiltersFields, filterKeys);

    setSelectedFilters(filtersSelected);
  }, [filters, partFiltersFields]);

  useEffect(handleSelectedFilters, [handleSelectedFilters]);

  const handleFilters = useCallback(() => {
    const source = Axios.CancelToken.source();

    const getFilterSet = async (queryArray: any) => {
      const allFilters = await queryArray.map(async (filterItem: any) => {
        const newFilterItem = objectFromEntries(filterItem);
        newFilterItem.t = selectedPartName;
        const filters = await getPartFilters(newFilterItem);

        return filters;
      });
      const filterSet = await Promise.all(allFilters);

      return filterSet;
    };

    const queryArray = Object.entries(filters) ? Object.entries(filters) : [];
    const newQueryArray: any = [];
    const reversePoppedKeyArray: any = [];

    queryArray.map((item, index, array) => {
      newQueryArray.push(array.slice(0, index + 1));
      reversePoppedKeyArray.push(item[0]);

      return null;
    });

    const poppedKeyArray = reversePoppedKeyArray.reverse();

    getFilterSet(newQueryArray.reverse()).then((filterArray) => {
      const allFilterSet: any = filterArray.reduce(
        (total: any, currentValue: any, currentIndex: number) => {
          const poppedKeyIndex = currentIndex - 1;
          const popValue = poppedKeyArray[poppedKeyIndex];

          if (popValue) {
            const newValue = {
              ...total,
              [popValue]: currentValue[popValue],
            };

            return newValue;
          }

          return currentValue;
        },
        {},
      );

      setPartFiltersData(allFilterSet);
    });

    return () => {
      // Cancel Request when unmounting
      source.cancel();
    };
  }, [filters, selectedPartName]);

  useEffect(handleFilters, [handleFilters]);

  const handleSelectedFiltersData = useCallback(() => {
    if (filters) {
      setSelectedFiltersData(filters);
    }
  }, [filters]);

  useEffect(handleSelectedFiltersData, [handleSelectedFiltersData]);

  const handleFilterSelection = (partFilter: PartFilterFields) => {
    setSelectedFilters((filters) => [...filters, partFilter]);
  };

  const handleValueSelection = (
    partFilter: PartFilterFields,
    value: string | null,
    rootKey?: string,
  ) => {
    const newSelectedFiltersData = convertStringKeyToObject(
      JSON.parse(JSON.stringify(selectedFiltersData)),
    );

    if (value !== null) {
      if (rootKey) {
        createDeepObject(newSelectedFiltersData, ['multiple', rootKey, partFilter.key], value);
      } else {
        createDeepObject(newSelectedFiltersData, [partFilter.key], value);
      }
    } else {
      if (rootKey) {
        delete newSelectedFiltersData['multiple'][rootKey];
      } else {
        delete newSelectedFiltersData[partFilter.key];
      }

      const index = selectedFilters.findIndex((e) => e.key === partFilter.key);

      if (index !== -1) {
        selectedFilters.splice(index, 1);
      }
    }

    handleFiltering(newSelectedFiltersData);
  };
  console.log('partFiltersData', partFiltersData);
  return (
    <>
      {showModal && part && (
        <PartsFilterAddModal
          handleFilterSelection={handleFilterSelection}
          handleValueSelection={handleValueSelection}
          isLoading={isLoading}
          open={showModal}
          part={part}
          partFilters={partFiltersFields}
          partFiltersData={partFiltersData}
          selectedFilters={selectedFilters}
          selectedFiltersData={selectedFiltersData}
          onClose={modalCloseHandler}
        />
      )}
    </>
  );
};

const defaultFilterField: PartFilterFields = {
  field: 'type',
  display_name: 'Type',
  key: 't',
};

const mapStateToProps = (state: AppState, props: WizardHeaderParametricSearchProps) => {
  const filtersFields: PartFilterFields[] = props.noDefaultFilterField ? [] : [defaultFilterField];
  const parts = getParts(state) || [];
  const partName = props.filters && props.filters.t ? props.filters.t : props.selectedPartName;

  if (partName && state.data.tenantConfig.data) {
    const part = selectPartByPartName(state.data.tenantConfig.data.parts, partName as string);

    if (part && part.filters && part.filters.select) {
      filtersFields.push(...part.filters.select);
    }

    if (part && part.filters && part.filters.numeric) {
      filtersFields.push(...part.filters.numeric);
    }

    if (part && part.filters && part.filters.multiple) {
      filtersFields.push(...part.filters.multiple);
    }
  }

  return {
    partFiltersFields: filtersFields,
    part: selectPartByPartName(parts, partName as string),
    isLoading: isStateLoading(state.action, 'partFilters', 'searchPartItems'),
  };
};

export default connect(mapStateToProps)(WizardHeaderParametricSearch);
